document.addEventListener("DOMContentLoaded", function() {
  let show = false;
  let list = document.getElementById("listProducts");
  let showProductsList = document.getElementById('showProductsList');
  if (showProductsList != null) {
    showProductsList.addEventListener(
      'click',
      () => {
        if (show) {
          list.className = "collapse";
          show = !show;
        } else {
          list.className = "show";
          show = !show;
        }
      }
    );
  }
});
