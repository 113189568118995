import { deleteHtmlElement, createHtmlElement, titleize, uuidGenerator } from './utils';

const inputElement = (key) => {
  let optionsInput = { elementType: 'input', type: 'text', className: 'form-control col col-lg-4 ml-1' };
  optionsInput.placeholder = titleize(key);
  optionsInput.name = `organization[segment_config][segment_sample_campaign_api_key][][${key}]`;
  let inputKey = createHtmlElement(optionsInput);
  return inputKey;
}

const sampleCampaignElements = () => {
  let elementId = `sample-campaign-key-${uuidGenerator()}`;
  let divContainer = createHtmlElement({
    id: elementId,
    elementType: 'div',
    className: 'form-group row justify-content-md-center'
  });
  divContainer.appendChild(inputElement('key'));
  divContainer.appendChild(inputElement('value'));
  let buttonDestroy = createHtmlElement({
    elementType: 'button',
    className: 'btn btn-sm btn-danger shadow-sm ml-1',
    type: 'button',
    innerHTML: '<i class="fa fa-trash fa-sm"></i>',
    onClickFunction: () => deleteHtmlElement(elementId)
  });
  divContainer.appendChild(buttonDestroy);
  let campaignSamplesContainer = document.getElementById('campaign-samples-container');
  if (campaignSamplesContainer) campaignSamplesContainer.appendChild(divContainer);
}

document.addEventListener("DOMContentLoaded", () => {
  let buttonAddField = document.getElementById('button-add-field');
  if (buttonAddField) {
    buttonAddField.addEventListener('click', () => { sampleCampaignElements() });
  }
});

window.deleteElement = (idElement) => { deleteHtmlElement(idElement) }
