$(document).ready(function() {
  if ($('#campaign-customers-datatable').length) {
    var default_options = window.datatableDefaultOptions;
    var table = {
      "ajax": {
        url: $('#campaign-customers-datatable').data('url'),
        data: function(d) {
          d.campaign_id = $('#filter_campaign').val()
        }
      }
    };
    let options = $.extend(default_options, table);
    $('#campaign-customers-datatable').DataTable(options);
    $('#filter_campaign').on('change', () => $('#campaign-customers-datatable').DataTable().ajax.reload());
    $('#export-campaign-customers').on('click', () => {
      $.ajax($('#campaign-customers-datatable').data('url').replace('.json', '.csv'), {
        type: 'get',  // http method
        data: $('#campaign-customers-datatable').DataTable().ajax.params(),
        success: function(data) {
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(new Blob([data]));
          link.download = "Campaign-Customers.csv";
          link.click();
        },
        error: function () {
          console.log('error');
        }
      });
    });
  }
});
