import React from 'react'
import ReactDOM from 'react-dom'

import axios from 'axios'

import CartItems from './CartItems'
import CartItem from './CartItem'
// import CartForm from './CartForm'
import Products from './Products'
import Spinner from '../Spinner'
import ErrorMessage from '../ErrorMessage'
class CartApp extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            cartItems: [],
            childTotals: {},
            isLoading: false,
            errorMessage: null,
            total: 0,
        }
        this.clildItemsRef = React.createRef()
        this.selectProduct = this.selectProduct.bind(this)
        this.createItem = this.createItem.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
        this.updateSubtotal = this.updateSubtotal.bind(this)
        this.handleErrors = this.handleErrors.bind(this)
        this.clearErrors = this.clearErrors.bind(this)
        this.changeChildState = this.changeChildState.bind(this)
    }

    componentDidMount() {
        if(this.props.item_carts){
            const cartItems = this.props.item_carts
            cartItems.map((cartItem, index) => {
                if(!cartItem.id){
                    cartItem.id = (index * Date.now() + Math.floor(Math.random() * 10)) * -1
                }
            });
            // const total = cartItems.reduce((prev, cur) => (prev + (parseFloat(cur.total))), 0)
            this.setState({ cartItems })
        }
    }

    selectProduct(product) {
        const new_item = {...product}
        this.createItem(new_item)
    }

    createItem(cartItem) {
        if(!cartItem.id){
            cartItem.id = (Date.now() + Math.floor(Math.random() * 10)) * -1
        }
        const cartItems = [cartItem, ...this.state.cartItems]
        this.setState({ cartItems })
    }

    deleteItem(obj, total) {
        const cartItems= this.state.cartItems.filter(item =>
            item.id !== obj.cart_item.id);
        var childTotals = this.state.childTotals
        delete(childTotals[obj.cart_item.id])
        this.setState({ cartItems, childTotals })
        this.changeChildState(total, 0)
    }

    updateSubtotal (key, total) {
        const childTotals = this.state.childTotals
        childTotals[key] = parseFloat(total)
        var total = 0
        for(key in childTotals){
            total += childTotals[key]
        }
        this.setState({ childTotals, total })
    }

    getTotal(){
        // return this.state.cartItems.reduce((prev, cur) => (prev + (parseFloat(cur.total))), 0)
    }
    handleErrors(errorMessage) {
        this.setState({ errorMessage })
    }
    clearErrors() {
        this.setState({
            errorMessage: null,
        })
    }
    changeChildState(prev_value, new_value) {
        var total = this.state.total
        total = total - parseFloat(prev_value) + parseFloat(new_value)
        this.setState({total})
    }

    label_total() {
        if (this.props.is_credit)
            return "Credit: ";
        else
            return "Total: $";
    }

    amount() {
        return <h3><mark className="bg-warning">{this.label_total()} {this.state.total}</mark></h3>
    }

    render() {
        return (
            <React.Fragment>
                {this.state.errorMessage && (
                    <ErrorMessage errorMessage={this.state.errorMessage} />
                )}
                {!this.state.isLoading && (
                    <React.Fragment>
                        <h4 className="text-primary">Select Products</h4>
                        <Products
                            selectProduct={this.selectProduct}
                            createItem={this.createItem}
                            handleErrors={this.handleErrors}
                            clearErrors={this.clearErrors}
                            is_credit={this.props.is_credit}
                            is_follow_up={this.props.is_follow_up}
                        />
                        <h4 className="text-primary">Selected Products</h4>
                        <CartItems ref={this.clildItemsRef}
                            is_credit={this.props.is_credit}
                            is_follow_up={this.props.is_follow_up}
                        >
                            {this.state.cartItems.map(cartItem => (
                                <CartItem
                                    key={cartItem.id}
                                    cart_item={cartItem}
                                    field_name={this.props.item_field_name}
                                    // getItems={this.getItems}
                                    handleErrors={this.handleErrors}
                                    clearErrors={this.clearErrors}
                                    onChange={this.changeChildState}
                                    onDelete={this.deleteItem}
                                    updateSubtotal={this.updateSubtotal}
                                    is_credit={this.props.is_credit}
                                    is_follow_up={this.props.is_follow_up}
                                />
                            ))}
                        </CartItems>
                        { !this.props.is_follow_up && this.amount() }
                        <input
                            type="hidden"
                            name="product_sample[cart_attributes][total]"
                            value={this.props.is_follow_up ? 0 : this.state.total}
                        />
                    </React.Fragment>
                )}
                {this.state.isLoading && <Spinner />}
            </React.Fragment>
        )
    }
}

document.addEventListener('turbolinks:load', () => {
    const cart_app = document.getElementById('cartComponent')
    cart_app && ReactDOM.render(<CartApp />, cart_app)
})

export default CartApp
import './cart.css'
