import React from 'react';

export const handleClickInfo = (type, value) => {
  let link;
  switch (type) {
    case 'email': link = `mailto:${value}`;
      break;
    case 'phone': link = `tel:${value}`;
      break;
    default: link = value;
  }
  window.open(link);
};

export const IconWithInfo = ({ label, icon, letter = "", className, ...props }) => {
  const type = label.includes('@') ? 'email' : label.includes('http') ? 'website' : 'phone';
  return (
    <div className='row'>
      {icon ? <img src={icon} className='icon' /> : <span className='letter-icon-bold'>{letter}</span>}
      <span className={`cursor-pointer ${className}`} {...props} onClick={() => handleClickInfo(type, label)}>
        {label}
      </span>
    </div>
  );
}
