// CREO NO SE USA
import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver';
import 'tinymce/skins/ui/oxide/skin.min';
import 'tinymce/skins/ui/oxide/content.min';
import 'tinymce/icons/default';
import 'tinymce/plugins/advlist/plugin';
import 'tinymce/plugins/autolink/plugin';
import 'tinymce/plugins/lists/plugin';
import 'tinymce/plugins/link';
import 'tinymce/plugins/table';
import 'tinymce/plugins/image/plugin';
import 'tinymce/plugins/print/plugin';
import 'tinymce/plugins/anchor/plugin';
import 'tinymce/plugins/code/plugin';
import 'tinymce/plugins/media/plugin';
import 'tinymce/plugins/paste/plugin';
import 'tinymce/plugins/help/plugin';
import 'tinymce/plugins/charmap/plugin';
import 'tinymce/plugins/preview/plugin';
import 'tinymce/plugins/searchreplace/plugin';
import 'tinymce/plugins/visualblocks/plugin';
import 'tinymce/plugins/fullscreen/plugin';
import 'tinymce/plugins/insertdatetime/plugin';
import 'tinymce/plugins/wordcount/plugin';
import 'tinymce/plugins/imagetools/plugin';


var activeTextArea = null;

function tinyMce(){
  tinymce.init({
    selector:'.tinymce',
    file_picker_types: 'file image media',
    file_browser_callback: "myCustomFileBrowser",
    // file_picker_callback: function(field_name, url, type, win){
    //     tinymce.activeEditor.windowManager.openUrl({
    //        title: 'Just a title',
    //        url: '/admin/resources/file_manager'
    //     });

    //     return false;
    // },
    image_title: true,
    menubar: false,
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount'
    ],
    toolbar: 'code undo redo | formatselect  | addResource |' +
    'bold italic backcolor | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent | ' +
    'removeformat | help',
    setup: function (editor) { // on the setup of the TinyMCE plugin, add a button with the alias 'addImage'
        editor.ui.registry.addButton('addResource', {
            text: 'Add Resource', // the text to display alongside the button
            icon: 'image', // the icon to display alongside the button
            onAction: function (_) {
                activeTextArea = editor; // onclick of this button, set the active textarea to that of which the button was pressed
                $ = window.$;
                $.get('/dashboard/resources/file_manager', function(data) {
                  $("#tmp_modal").remove();
                  $("body").append("<div id='tmp_modal'></div>");
                  $("#tmp_modal").append(data);
                  return $($("#tmp_modal .modal").first()).modal("show");
                });
            }
        });
    },
    // image_list: function(success) {
    //   success([
    //     {title: 'Dog', value: 'mydog.jpg'},
    //     {title: 'Cat', value: 'mycat.gif'}
    //   ]);
    // }
  });
}

export { tinyMce };
