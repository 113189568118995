import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import axios from 'axios'
import setAxiosHeaders from '../AxiosHeaders'
class CartItem extends React.Component {
    constructor(props) {
        super(props)
        this.handleDestroy = this.handleDestroy.bind(this)
        this.handleChange = this.handleChange.bind(this)
        // this.updateCartItem = this.updateCartItem.bind(this)
        this.quantityRef = React.createRef()
        this.totalRef = React.createRef()
        this.path = `/api/v1/cart_items/${props.cart_item.id}`
        this.state = {
            total: parseFloat(props.cart_item.total),
        }
    }
    componentDidMount() {
        this.setState( { total: this.props.cart_item.total })
        this.props.updateSubtotal(this.props.cart_item.id, this.props.cart_item.total)
        // if (this.props.onChange) {
        //     this.props.onChange(0, this.state.total);
        // }
    }
    handleChange(event) {
        const quantity = parseFloat(event.target.value)
        // const quantity = parseFloat(this.quantityRef.current.value)
        const price = parseFloat(this.props.cart_item.unit_price)
        const total = quantity * price
        const prev_total = this.state.total
        this.setState({ total:total })
        // if (this.props.onChange) {
        //     this.props.onChange(prev_total, total);
        // }
        this.props.updateSubtotal(this.props.cart_item.id, total)

    }
    handleDestroy() {
        const confirmation = confirm('Are you sure?')
        if (confirmation) {
            this.props.onDelete(this.props, this.state.total)
        }
    }
    field_name(id, name) {
        const container = this.props.field_name || 'item_cart'
        return `${container}[${id}][${name}]`
    }

    text_amount(){
        var label = ""
        if(!this.props.is_credit)
            label = "$ "
        return label + this.props.cart_item.unit_price
    }
    render() {
        const { cart_item } = this.props
        return (
            <tr className="">
                <td>
                    <p>{cart_item.product_sku}</p>
                </td>
                <td>
                    <p>{cart_item.product_name}</p>
                </td>
                { !this.props.is_follow_up && <td><p>{this.text_amount()}</p></td>}
                <td>
                    <input type="hidden"
                        value={this.props.cart_item.product_id}
                        name={this.field_name(cart_item.id, "product_id")}
                    />
                    <input type="hidden"
                        value={this.props.cart_item.unit_price}
                        name={this.field_name(cart_item.id, "unit_price")}
                    />
                    <input
                        type="number"
                        defaultValue={this.props.cart_item.quantity}
                        onChange={ this.handleChange }
                        ref={ this.quantityRef }
                        className="form-control"
                        min="1"
                        step="1"
                        name={this.field_name(cart_item.id, "quantity")}
                        id={`cart_item_quantity-${cart_item.id}`}
                    />
                </td>
                { !this.props.is_follow_up && (
                    <td>
                        <input
                            type="text"
                            value= {this.state.total}
                            ref={this.totalRef}
                            className="form-control"
                            name={this.field_name(cart_item.id, "total")}
                            id={`cart_item_total-${cart_item.id}`}
                            readOnly="readonly"
                        />
                    </td>
                )}
                <td className="text-right">
                    <button
                        onClick={this.handleDestroy}
                        className="btn btn-outline-danger"
                    >
                        Delete
                    </button>
                </td>
            </tr>
        )
    }
}

export default CartItem

CartItem.propTypes = {
    cart_item: PropTypes.object.isRequired,
    // getCartItems: PropTypes.func.isRequired,
    // hideCompletedTodoItems: PropTypes.bool.isRequired,
    clearErrors: PropTypes.func.isRequired,
}
