$(document).ready(function() {
  var table = $('#users-credit').DataTable({
    'processing': true,
    'serverSide': true,
    'ajax': $('#users-credit').data('url'),
    'columnDefs': [
        {
          'targets': 0,
          'checkboxes': {
              'selectRow': true
          }
        }
    ],
    'select': {
        'style': 'multi'
    },
    'order': [[1, 'asc']]
  });

  $('#form-users-credit').on('submit', function(e) {
    var form = this;
    var rows_selected = table.column(0).checkboxes.selected();
    $.each(rows_selected, function(index, rowId){
      $(form).append(
        $('<input>')
          .attr('type', 'hidden')
          .attr('name', 'user_ids[]')
          .val(rowId)
      );
    });
  });
});
