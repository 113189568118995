import React from "react";
import {handleClickInfo} from "../IconWithInfo";

const MeasureAndMadeInfo = ({data}) => {
  const {
    firstname = '',
    lastname = '',
    externalid = '',
    customerid = '',
    email_address,
    cell_phone,
  } = data || {};
  const name = `${firstname} ${lastname}`;
  const site = `https://shapesetter.measureandmade.com/${externalid}`;

  const Info = ({type, label, className, ...props}) => (
    <span className={`cursor-pointer ${className}`} onClick={() => handleClickInfo(type, label)} {...props}>
      {label}
    </span>
  )
  return(
  <>
    <div className='business-card-info'>
      <span className='business-card-username'>{name}</span>
      <span className='business-card-shapesetter'>SHAPESETTER</span>
    </div>
    <div className='business-card-info'>
      {cell_phone && (
        <Info type="phone" label={cell_phone}/>
      )}
      {email_address && (
        <Info className='business-card-email' type="email" label={email_address}/>
      )}
      <span>Shapesetter ID: #{customerid}</span>
      <Info type="web" label={site}/>
    </div>
  </>
  );
};

export default MeasureAndMadeInfo;
