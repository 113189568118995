import React from 'react'
import PropTypes from 'prop-types'
import PsUserItem from './PsUserItem'
import Spinner from '../Spinner'
import ErrorMessage from '../ErrorMessage'

// Product Sample - Users
class PsUserApp extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            items: [],
            isLoading: false,
            errorMessage: null,
        }
        this.createItem = this.createItem.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
        this.handleErrors = this.handleErrors.bind(this)
        this.clearErrors = this.clearErrors.bind(this)
    }

    componentDidMount() {
        if(this.props.user_items){
            const items = this.props.user_items
            items.forEach(function(item, index) {
                if(!item.id){
                    item.id = (Date.now() + Math.floor(Math.random() * 10)) * -1
                }
            })
            this.setState({ items })
        }
    }

    createItem(event) {
        const item = {
            id: (Date.now() + Math.floor(Math.random() * 10)) * -1,
            quantity:  1,
            user_id: 0
        }
        const items = [item, ...this.state.items]
        this.setState({ items })
        event.preventDefault();
    }

    deleteItem(obj) {
        const filteredItems= this.state.items.filter(item =>
            item.id!==obj.item.id);
        this.setState({
            items: filteredItems
        })
    }

    handleErrors(errorMessage) {
        this.setState({ errorMessage })
    }

    clearErrors() {
        this.setState({
            errorMessage: null,
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.state.errorMessage && (
                    <ErrorMessage errorMessage={this.state.errorMessage} />
                )}
                {!this.state.isLoading && (
                    <React.Fragment>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="card shadow mb-4 border-left-primary">
                              <div className="d-flex">
                                <div className="p-2"><h6 className="ml-3 mt-3 font-weight-bold text-primary"> Users </h6></div>
                                <div className="ml-auto p-2"><button className="mr-3 mt-3 btn btn-sm btn-success" onClick={this.createItem}> Add </button></div>
                              </div>
                              <div className="card-body">
                                  <table className="table">
                                      <thead>
                                        <tr className="text-center">
                                            <th>User</th>
                                            <th>Quantity</th>
                                            <th className="text-right">Action</th>
                                        </tr>
                                      </thead>
                                      <tbody className="text-center">
                                        {this.state.items.map((item, index) => (
                                            <PsUserItem
                                                key={item.id}
                                                item={item}
                                                users={this.props.users}
                                                field_name={this.props.item_field_name}
                                                handleErrors={this.handleErrors}
                                                clearErrors={this.clearErrors}
                                                onDelete={this.deleteItem}
                                            />
                                        ))}
                                      </tbody>
                                  </table>
                              </div>
                            </div>
                          </div>
                        </div>
                    </React.Fragment>
                )}
                {this.state.isLoading && <Spinner />}
            </React.Fragment>
        )
    }
}

PsUserApp.propTypes = {
    users: PropTypes.array.isRequired,
}

export default PsUserApp
