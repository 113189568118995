import React from 'react';
import webImg from '../../../../assets/images/icons/theorem/web.png';
import emailImg from '../../../../assets/images/icons/theorem/email.png';
import phoneImg from '../../../../assets/images/icons/theorem/phone.png';
import {IconWithInfo} from '../IconWithInfo';

const TheoremInfo = ({data}) => {
  const {
    fullname = '',
    email_address,
    cell_phone,
    site_url,
    rank,
  } = data || {};

  return(
    <>
      <div className='business-card-info theorem'>
        <span className='business-card-username-bold'>{fullname.toUpperCase()}</span>
        <span className='business-card-rank'>{rank.toUpperCase()}</span>
      </div>
      <div className='business-card-info theorem'>
        <div className='business-card-line' />
        {cell_phone && (
          <IconWithInfo icon={phoneImg} label={cell_phone}/>
        )}
        {site_url && (
          <IconWithInfo icon={webImg} label={site_url}/>
        )}
        {email_address && (
          <IconWithInfo
            className='business-card-email'
            label={email_address}
            icon={emailImg}
          />
        )}
      </div>
    </>
  );
};

export default TheoremInfo;
