import React from 'react'
import PropTypes from 'prop-types'

class CartItems extends React.Component {
    constructor(props) {
        super(props)
    }

    label_amount(){
        if (this.props.is_credit)
            return "Credit";
        else
            return "Price";
    }

    render() {
        return (
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr className="text-center">
                                <th scope="col">SKU</th>
                                <th scope="col">Product</th>
                                { !this.props.is_follow_up && <th scope="col">{this.label_amount()}</th> }
                                <th scope="col">Quantity</th>
                                { !this.props.is_follow_up && <th scope="col">Total</th> }
                                <th scope="col" className="text-right">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody className="text-center">{this.props.children}</tbody>
                    </table>
                </div>
        )
    }
}
export default CartItems
