$(document).ready(function() {
  if ($('#csv-imported').length){
    var default_options = window.datatableDefaultOptions;
    var table = {
      "ajax": { url: $('#csv-imported').data('url') }
    };
    var options = $.extend(default_options, table);
    $('#csv-imported').DataTable(options);
  }
});
