$(document).ready(function() {
  $('#product-samples-index-datatable').DataTable( {
    "processing": true,
    "serverSide": true,
    "language": {
      sEmptyTable: "Nothing to show",
      search: '<i class="fa fa-filter text-primary" aria-hidden="true"></i>',
      searchPlaceholder: 'Search'
    },
    "ajax": {
      url: $('#product-samples-index-datatable').data('url'),
      method: 'GET',
      data: {}
    },
    responsive: true,
    aoColumnDefs: [
        { aTargets: [4], sortable: false }
    ],
    iDisplayLength: 25,
    searchDelay: 550,
    order: [[ 6, "desc" ]]

  });
});