const Swal = require('sweetalert2/dist/sweetalert2.all.min')

$(document).on('turbolinks:load', () => {
  $('.sweet-alert-destroy').click(function(){
    Swal.fire({
      title: 'Destroy',
      text: 'Confirm destroy element?',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Confirm',
    }).then((result) => {
      if (result.isConfirmed) {
        $.ajax({
          url: $('.sweet-alert-destroy').data('url'),
          type: 'DELETE',
          success: () => { },
          error: function(xhr) {
            Swal.fire({
              icon: 'error',
              title: 'Something went wrong!',
              text: xhr.statusText,
            });
          }
        });
      }
    });
  });

  window.alertDestroyCSV  = function (id) {
    Swal.fire({
      title: 'Undo credits',
      text: 'Confirm undo credits of this file?',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Confirm',
    }).then((result) => {
      if (result.isConfirmed) {
        $.ajax({
          url:$(`#button-destroy-csv-${id}`).data('url'),
          type: 'DELETE',
          error: function(xhr) {
            Swal.fire({
              icon: 'error',
              title: 'Something went wrong!',
              text: xhr.statusText,
            });
          }
        });
      }
    });
  }
});
