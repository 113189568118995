import React, { useEffect, useState } from 'react';
import Card from './business_card/Card';
import ErrorMessage from './ErrorMessage';

const VirtualBusinessCard = ({organizationKey, userData, errors}) => {
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState();
  const [images, setImages] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    setProfile(userData?.data?.profile);
    setImages(userData?.data?.cards);
    setError(errors?.join(', '));
    document.getElementsByClassName('container-fluid')[0].classList.add(organizationKey);
  }, [organizationKey, userData, errors]);

  return (
    <div className={`business-card-container ${organizationKey}`}>
      {error && (
        <div className='business-card-content'>
          <ErrorMessage errorMessage={error} />
        </div>
      )}
      {profile && images && (
        <>
          {images?.card_front && <Card logo img={images?.card_front} organizationKey={organizationKey} />}
          {images?.card_back && <Card img={images?.card_back} data={profile} organizationKey={organizationKey} />}
        </>
      )}
  </div>
  );
}

export default VirtualBusinessCard;
