$(document).ready(function() {
  if ($('#orders-index-datatable').length){
    var default_options = window.datatableDefaultOptions;
    var table = {
      "ajax": {
        url: $('#orders-index-datatable').data('url'),
        method: 'GET',
        data: {}
      }
    };
    var options = $.extend(default_options, table);
    $('#orders-index-datatable').DataTable(options);

    if($('#filter_datatable').length){
      $('#filter_datatable .filter').on('change', function(e){

      });
    }
  }
});

OrderDatatable = {};

OrderDatatable.IndexDataTable = {
    init: function(table_selector) {
      var default_options = window.datatableDefaultOptions;
      var table = {
        "ajax": {
          url: $(table_selector).data('url'),
          method: 'GET',
          data: function(d){
            d.filters = OrderDatatable.IndexDataTable.filter_values();
            return d;
          },
        }
      };
      var options = $.extend(default_options, table);
      options.order = [[7,'desc']];
      var datatable = $(table_selector).DataTable(options);

      // initialize filters
      // Filter Order Emission at
      $('#filter_order_emission_at').daterangepicker(window.dataRangeOptions());
        $('#filter_order_emission_at').val('')

      // enable button clear daterange
      $('#filter_order_emission_at').on('cancel.daterangepicker', function(ev, picker) {
        $(this).val('');
        $(this).trigger('change');
      });

      // Filter Order Shipping date
      $('#filter_shipping_date').daterangepicker(window.dataRangeOptions());
        $('#filter_shipping_date').val('')

      // enable button clear daterange
      $('#filter_shipping_date').on('cancel.daterangepicker', function(ev, picker) {
        $(this).val('');
        $(this).trigger('change');
      });

      $('#filter_seller').select2({
        minimumInputLength: 3,
        placeholder: "Seller Names",
        allowClear: true,
        ajax: {
          url: $('#filter_seller').data("url"),
          dataType: 'json',
          delay: 200,
          cache: true,
          data: function(params) {
            return {
              term: params.term
            };
          },
          processResults: function (data) {
            return {
              results : data.map(function(user) {
                return {
                  id : user.id,
                  text : `${user.full_name}`,
                  title : `${user.full_name}`,
                  user: user
                };
              })
            }
          }
        }
      });


      // $('#filter_seller').on('change', function(e){
      //   alert("DEBERIA BUSCAR");
      // });



      $('.filter_datatable').on('change', function(e){
        datatable.ajax.reload();
      });
    },
    filter_values: function(){
      var f = {};
      if($('#filter_order_status').length)
        f.order_status = $('#filter_order_status').val();
      if($('#filter_order_number').length)
        f.order_number = $('#filter_order_number').val();
      if($('#filter_seller').length)
        f.order_seller = $('#filter_seller').val();
      if($('#filter_customer').length)
        f.order_customer = $('#filter_customer').val();
      if($('#filter_order_emission_at').length)
        f.order_emission_at = $('#filter_order_emission_at').val();
      if($('#filter_shipping_status').length)
        f.order_shipping_status = $('#filter_shipping_status').val();
      if($('#filter_shipping_date').length)
        f.order_shipping_date = $('#filter_shipping_date').val();
      return f;
    },
    export: function(table_selector){
      var datatable = $(table_selector).DataTable();
        url = datatable.ajax.url().replace(".json", ".csv")
        params = $.param(datatable.ajax.params())
        window.location.href = url + '?' + params
    }
};
