import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

class PsTouItem extends React.Component {
    constructor(props) {
        super(props)
        this.handleDestroy = this.handleDestroy.bind(this)
        this.state = {
            user_type_id: props.item.type_of_user_id || 0,
            quantity: props.item.quantity,
            destroy: 0
        }
    }

    handleDestroy(event) {
        if(this.props.item.id < 0)
            this.props.onDelete(this.props)
        else{
            const confirmation = confirm('Are you sure?')
            if (confirmation) {
                this.setState({ destroy: 1 })
            }
        }
        event.preventDefault();
    }

    field_name(id, name) {
        const container = this.props.field_name
        return `${container}[${id}][${name}]`
    }

    render() {
        const { item } = this.props
        const renderHiddenId = (item)=> {
            if(item.id > 0){
                return  <React.Fragment>
                            <input type='hidden' name={this.field_name(item.id, 'id')} value={item.id} />
                            <input type='hidden' name={this.field_name(item.id, '_destroy')} value={this.state.destroy} />
                        </React.Fragment>
            } else{
                return ""
            }
        }

        return (
            <tr className={ this.state.destroy ? 'd-none' : 'cacaca'}>
                <td className="w-50">
                    {renderHiddenId(item)}
                    <select
                        name={this.field_name(item.id, "type_of_user_id")}
                        defaultValue={this.state.user_type_id}
                        className="form-control"
                    >
                        {this.props.user_types.map(ut => (
                            <option value={ut[1]}>{ut[0]}</option>
                        ))}
                    </select>
                </td>
                <td>
                    <input
                        type="number"
                        defaultValue={this.state.quantity}
                        className="form-control"
                        min="0"
                        step="1"
                        name={this.field_name(item.id, "quantity")}
                        id={`item_quantity-${item.id}`}
                    />
                </td>
                <td className="text-right">
                    <button
                        onClick={this.handleDestroy}
                        className="btn btn-outline-danger"
                    >
                        <i className="fas fa-trash"></i>
                    </button>
                </td>
            </tr>
        )
    }
}

export default PsTouItem

PsTouItem.propTypes = {
    item: PropTypes.object.isRequired,
    user_types:  PropTypes.array.isRequired,
    clearErrors: PropTypes.func.isRequired,
}
