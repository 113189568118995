// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("jquery")
// import $ from 'jquery';
// window.jQuery = $;
// window.$ = $;

var jQuery = require("jquery");

// import jQuery from "jquery";
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

var moment = require('moment')
// include moment in global and window scope (so you can access it globally)
global.moment = moment;
window.moment = moment;

require("channels");
require("bootstrap");

require("./sbadmin2/jquery/jquery.min");
require("./sbadmin2/bootstrap/bootstrap.bundle.min");
require("./sbadmin2/jquery-easing/jquery.easing.min");
require("./sbadmin2/sb-admin-2");
require("datatables.net-bs4");
require("./datatable/require_datatable");
require("./preview_image");
require("./flash");
require("./resources");
require("./sweet_alert2");
require("./date_range_input");
require("select2/dist/js/select2.full.min");
import("select2/dist/css/select2.min");
require("./checkboxDatatable.min");
require("./importCSV");
require("./desplegableListProducts");
require("./campaignAddApiKeys");

import { tinyMce } from "./tinyMce";
document.addEventListener("turbolinks:load", function () {    tinyMce(); });

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})
import '../stylesheets/application'
