import React from 'react';
import webImg from '../../../../assets/images/icons/bravenly_global/web.png';
import emailImg from '../../../../assets/images/icons/bravenly_global/email.png';
import phoneImg from '../../../../assets/images/icons/bravenly_global/phone.png';
import {IconWithInfo} from '../IconWithInfo';

const BravenlyGlobalInfo = ({data}) => {
  const {
    fullname = '',
    email_address,
    cell_phone,
    site_url,
    rank,
  } = data || {};

  return(
    <>
      <div className='business-card-info'>
        <div className='column'>
          <span className='business-card-username-bold'>{fullname.toUpperCase()}</span>
          <span className='business-card-rank'>{rank.toUpperCase()}</span>
        </div>
        {email_address && (
          <IconWithInfo
            className='business-card-email'
            label={email_address.toLowerCase()}
            icon={emailImg}
          />
        )}
        {site_url && (
          <IconWithInfo icon={webImg} label={site_url.toLowerCase()}/>
        )}
        {cell_phone && (
          <IconWithInfo icon={phoneImg} label={cell_phone}/>
        )}
      </div>
    </>
  );
};

export default BravenlyGlobalInfo;
