$(document).ready(function() {
  if ($('#customers-index-datatable').length){
    var default_options = window.datatableDefaultOptions;
    var table = {
      "ajax": {
        url: $('#customers-index-datatable').data('url'),
        method: 'GET',
        data: {}
      }
    };
    var options = $.extend(default_options, table);
    $('#customers-index-datatable').DataTable(options);

    if($('#filter_datatable').length){
      $('#filter_datatable .filter').on('change', function(e){

      });
    }
  }
});

CustomerDatatable = {};

CustomerDatatable.IndexDataTable = {
    init: function(table_selector) {
      var default_options = window.datatableDefaultOptions;
      var table = {
        "ajax": {
          url: $(table_selector).data('url'),
          method: 'GET',
          data: function(d){
            d.filters = CustomerDatatable.IndexDataTable.filter_values();
            return d;
          },
        }
      };
      default_options["columnDefs"] = [ {
        "targets": 8,
        "orderable": false
        } ];
      var options = $.extend(default_options, table);
      var datatable = $(table_selector).DataTable(options);
      datatable =  datatable.order([[7,'desc']]);

      // initialize filters
      // Filter Order Emission at
      $('#filter_order_emission_at').daterangepicker(window.dataRangeOptions());
        $('#filter_order_emission_at').val('')

      // enable button clear daterange
      $('#filter_order_emission_at').on('cancel.daterangepicker', function(ev, picker) {
        $(this).val('');
        $(this).trigger('change');
      });

      $('.filter_datatable').on('change', function(e){
        datatable.ajax.reload();
      });
    },
    filter_values: function(){
      var f = {};
      if($('#filter_order_emission_at').length)
        f.order_emission_at = $('#filter_order_emission_at').val();
      return f;
    },
    export: function(table_selector){
      var datatable = $(table_selector).DataTable();
        url = datatable.ajax.url().replace(".json", ".csv")
        params = $.param(datatable.ajax.params())
        window.location.href = url + '?' + params
    }
};
