import React from 'react';
import {IconWithInfo} from '../IconWithInfo';

const JumpToHealthInfo = ({data}) => {
  const {
    fullname = '',
    email_address,
    cell_phone,
    site_url,
    rank = '',
  } = data || {};

  return(
    <>
      <div className='business-card-info'>
        <div className='column'>
          <span className='business-card-username-bold'>{fullname}</span>
          <span className='business-card-rank'>{rank}</span>
        </div>
        {cell_phone && (
          <div className='phone-container'>
            <span className='phone-text'>phone: </span>
            <IconWithInfo label={cell_phone}/>
          </div>
        )}
        {email_address && (
          <div className='phone-container'>
            <span className='phone-text'>email: </span>
            <IconWithInfo label={email_address}/>
        </div>
        )}
        {site_url && (
          <div className='neg-margin'>
            <IconWithInfo label={site_url}/>
          </div>
        )}
      </div>
    </>
  );
};

export default JumpToHealthInfo;
