import React from 'react';
import {IconWithInfo} from '../IconWithInfo';

const AkannaInfo = ({data}) => {
  const {
    fullname = '',
    email_address,
    cell_phone,
    site_url,
    rank = '',
  } = data || {};

  return(
    <>
      <div className='business-card-info'>
        <div className='column'>
          <span className='business-card-username-bold'>{fullname?.toUpperCase()}</span>
          <span className='business-card-rank'>{rank?.toUpperCase()}</span>
        </div>
        {cell_phone && (
          <div className='phone-container'>
            <span className='phone-text'>TEL | </span>
            <IconWithInfo label={cell_phone}/>
          </div>
        )}
        {email_address && (
          <IconWithInfo
            className='business-card-email'
            label={email_address.toUpperCase()}
          />
        )}
        {site_url && (
          <IconWithInfo label={site_url.toUpperCase()}/>
        )}
      </div>
    </>
  );
};

export default AkannaInfo;
