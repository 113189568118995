require("daterangepicker/daterangepicker")
import 'daterangepicker/daterangepicker.css'
// Data Range Picker Options
window.dataRangeOptions = function(attrs){
  if (attrs == undefined)
    attrs = {}
  return {
    autoUpdateInput: true,
    showDropdowns: true,
    timePicker: false,
    timePickerIncrement: 1,
    timePicker12Hour: false,
    cancelButtonClasses: "btn-xs btn-warning btn",
    opens: 'left',
    // autoApply: true,
    ranges: {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Previous Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    },
    separator: attrs['separator'] || ' to ',
    alwaysShowCalendars: true,
    locale: {
        separator: attrs['separator'] || ' , ',
        format: 'MM-DD-YYYY',
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
        minDate: '01-01-2020',
        applyLabel: 'Submit',
        cancelLabel: 'Clear',
        fromLabel: 'From',
        toLabel: 'To',
        customRangeLabel: 'Custom',
        daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        firstDay: 0
    },
  }
}