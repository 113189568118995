$(document).ready(function() {
  $('#products-index-datatable').DataTable( {
    "processing": true,
    "serverSide": true,
    "language": {
      sEmptyTable: "Nothing to show",
      search: '<i class="fa fa-filter text-primary" aria-hidden="true"></i>',
      searchPlaceholder: 'Search'
    },
    ajax: {
      url: $('#products-index-datatable').data('url'),
    },
    responsive: true,
    aoColumnDefs: [
      { aTargets: [7], width: '20px', sortable: false }
    ],
    iDisplayLength: 25,
    searchDelay: 550
  });
});