$(document).ready(function() {
  if ($('#templates-index-datatable').length){
    var default_options = window.datatableDefaultOptions;
    var table = {
      "ajax": {
        url: $('#templates-index-datatable').data('url'),
        method: 'GET',
        data: {}
      },
      aoColumnDefs: [
        { aTargets: [4], width: '20px', sortable: false }
      ],
    };
    var options = $.extend(default_options, table);
    $('#templates-index-datatable').DataTable(options);
  }
});