import React from 'react';
import {IconWithInfo} from '../IconWithInfo';
import webImg from '../../../../assets/images/icons/colorstreet/web.png';
import phoneImg from '../../../../assets/images/icons/colorstreet/phone.png';
import emailImg from '../../../../assets/images/icons/colorstreet/email.png';

const ColorStreetInfo = ({data}) => {
  const {
    fullname = '',
    email_address,
    cell_phone,
    site_url,
    rank = '',
  } = data || {};

  return(
    <>
      <div className='business-card-info'>
        <div className='column'>
          <span className='business-card-username-bold'>{fullname}</span>
          <span className='business-card-rank'>{rank}</span>
        </div>
        <div className='info-container'>
          {cell_phone && (
            <IconWithInfo label={cell_phone} icon={phoneImg}/>
          )}
          {email_address && (
            <IconWithInfo
              className='business-card-email'
              label={email_address.toUpperCase()}
              icon={emailImg}
            />
          )}
          {site_url && (
            <IconWithInfo label={site_url.toUpperCase()} icon={webImg} />
          )}
        </div>
      </div>
    </>
  );
};

export default ColorStreetInfo;
