$(document).ready(function() {
  if ($('#users-index-datatable').length){
    var default_options = window.datatableDefaultOptions;
    var table = {
      "ajax": {
        url: $('#users-index-datatable').data('url'),
        method: 'GET',
        data: {}
      },
      // aoColumnDefs: [
      //   { aTargets: [8], width: '20px', sortable: false }
      // ],
    };
    var options = $.extend(default_options, table);
    $('#users-index-datatable').DataTable(options);
  }
});