import React from 'react';
import {IconWithInfo} from '../IconWithInfo';

const WeightlessInfo = ({data}) => {
  const {
    fullname = '',
    email_address,
    cell_phone,
  } = data || {};
  return(
    <>
      <div className='business-card-info'>
        <div className='column'>
          <span className='business-card-username-bold'>{fullname}</span>
        </div>
        {cell_phone && (
          <IconWithInfo label={cell_phone}/>
        )}
        {email_address && (
          <IconWithInfo label={email_address.toLowerCase()} />
        )}
      </div>
    </>
  );
};

export default WeightlessInfo;
