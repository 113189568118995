import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import axios from 'axios'

import Product from './Product'

class Products extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            products: [],
        }
        this.getProducts = this.getProducts.bind(this)
        // this.selectProduct = this.props.selectProduct.bind(this)
    }
    componentDidMount() {
        this.getProducts()
    }
    getProducts() {
        var params = {}
        if (this.props.is_credit)
            params = { params: { with_credit: true } }
        else
            params = { params: { with_credit: false } }

        axios
            .get('/api/v1/products/items', params)
            .then(response => {
                this.props.clearErrors()
                // this.setState({ isLoading: true })
                const products = response.data
                this.setState({ products: products })
                // this.setState({ isLoading: false })
            })
            .catch(error => {
                this.props.handleErrors({
                    errorMessage: {
                        message:
                            'There was an error loading your todo items...',
                        error: error
                    },
                })
            })
    }
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    {this.state.products.map(product => (
                            <Product
                                key={product.product_id}
                                product={product}
                                selectProduct={this.props.selectProduct}
                                handleErrors={this.props.handleErrors}
                                clearErrors={this.props.clearErrors}
                                is_credit={this.props.is_credit}
                                is_follow_up={this.props.is_follow_up}
                            />
                        ))}
                </div>
            </React.Fragment>
        )
    }
}

export default Products
Products.propTypes = {
    selectProduct: PropTypes.func.isRequired,
    handleErrors: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
}