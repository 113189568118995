export const createHtmlElement = ({
  elementType,
  id,
  className,
  styles,
  textContent,
  placeholder,
  type,
  name,
  innerHTML,
  onClickFunction
}) => {
  let element = document.createElement(elementType);
  if (id && id.length) element.id = id;
  if (className && className.length) element.className = className;
  if (styles && styles.length) element.style = styles;
  if (textContent && textContent.length) element.textContent = textContent;
  if (placeholder && placeholder.length) element.placeholder = placeholder;
  if (type && type.length) element.type = type;
  if (name && name.length) element.name = name;
  if (innerHTML && innerHTML.length) element.innerHTML = innerHTML;
  if (onClickFunction && typeof onClickFunction === 'function') element.onclick = onClickFunction;
  return element;
}

export const deleteHtmlElement = (elementId) => {
  let element = document.getElementById(elementId);
  if (element) element.remove();
}

export const addOptionIntoSelect = (select, item) => {
  let option = document.createElement("option");
  option.text = item.name;
  option.value = item.key;
  select.appendChild(option);
}

export const removeOptionsToSelect = (selectElement) => {
  let n  = selectElement.options.length - 1;
  let i = n, length = n;
  for (i = length; i >= 0; i--) selectElement.remove(i);
}

export const titleize = (str) => {
  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

export const uuidGenerator = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}
