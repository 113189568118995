window.datatableDefaultOptions = {
  processing: true,
  serverSide: true,
  language: {
    sEmptyTable: "Nothing to show",
    search: '<i class="fa fa-filter text-primary" aria-hidden="true"></i>',
    searchPlaceholder: 'Search'
  },
  responsive: true,
  iDisplayLength: 25,
  searchDelay: 550
};

require("./order");
require("./customer");
require("./product");
require("./product_sample");
require("./resource");
require("./template");
require("./user");
require("./csvImported");
require("./usersCredit");
require("./campaign");

$(document).ready(function() {
  if ($('.simple_dataTable').length){
    $('.simple_dataTable').DataTable({
      language: {
        sEmptyTable: "Nothing to show",
        search: '<i class="fa fa-filter text-primary" aria-hidden="true"></i>',
        searchPlaceholder: 'Search'
      },
      responsive: true,
      iDisplayLength: 50,
    });
  }
});
