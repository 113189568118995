import React from 'react';
import {IconWithInfo} from '../IconWithInfo';

const AquabiovidaInfo = ({data}) => {
  const {
    fullname = '',
    email_address = '',
    cell_phone = '',
    rank = '',
    site_url = '',
  } = data || {};

  return(
    <>
      <div className='business-card-info'>
        <div className='column'>
          <IconWithInfo label={site_url.toLowerCase()}/>
          <span className='business-card-username-bold'>{fullname}</span>
          <span className='business-card-rank'>{rank}</span>
        </div>
        <div className='info-container'>
          {cell_phone && (
            <div className='phone-container'>
              <IconWithInfo label={`Cell | ${cell_phone}`}/>
            </div>
          )}
          {email_address && (
            <IconWithInfo
              className='business-card-email'
              label={email_address.toLowerCase()}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AquabiovidaInfo;
