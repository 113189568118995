import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Select2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';

class PsUserItem extends React.Component {
    constructor(props) {
        super(props)
        this.handleDestroy = this.handleDestroy.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.state = {
            user_id: props.item.user_id || 0,
            quantity: props.item.quantity,
            destroy: 0
        }
    }

    handleDestroy(event) {
        if(this.props.item.id < 0)
            this.props.onDelete(this.props)
        else{
            const confirmation = confirm('Are you sure?')
            if (confirmation) {
                this.setState({ destroy: 1 })
            }
        }
        event.preventDefault();
    }

    handleChange(event){
        this.setState({ user_id: event.params.data.id })
    }

    field_name(id, name) {
        const container = this.props.field_name
        return `${container}[${id}][${name}]`
    }

    render() {
        const { item } = this.props
        const renderHiddenId = (item)=>{
            if(item.id > 0){
                return <React.Fragment>
                    <input type='hidden' name={this.field_name(item.id, 'id')} value={item.id} />
                    <input type='hidden' name={this.field_name(item.id, '_destroy')} value={this.state.destroy} />
                    </React.Fragment>
            } else{
                return ""
            }
        }

        const dataUser = () => {
            var data = []
            this.props.users.map(user => {
                var user = {
                    id : user[1],
                    text : `[${user[3] || '#'}] ${user[0]} (${user[2]})`,
                    title : `[${user[3] || '#'}] ${user[0]} (${user[2]})`,
                }
                data = [user, ...data]
            })
            return data
        }

        var _options = {
            placeholder: 'Search user',
            allowClear: true,
            dropdownAutoWidth:true,
            minimumInputLength: 3,
            templateResult: function(data){
                    if(data.user == undefined)
                        return data.text;
                var customer_id = `<strong>Customer Id: </strong> ${data.user.customer_id || '[#]'}`
                var full_name = `<strong>Full Name: </strong> ${data.user.full_name}`
                var email = `<strong>Email: </strong> ${data.user.email}`
                return `${customer_id} - ${full_name} - ${email}`
            },
            escapeMarkup: function(markup) {
                return markup;
            },
            templateSelection: function(data) {
                return data.text;
            },
            ajax: {
                url: '/dashboard/users/search',
                dataType: 'json',
                delay: 200,
                cache: true,
                data: function(params) {
                    return {
                        term: params.term
                    };
                },
                processResults: function (data) {
                    return { results : data.map(function(user) {
                                return {
                                    id : user.id,
                                    text : `[${user.customer_id || '#'}] ${user.full_name} (${user.email})`,
                                    title : `[${user.customer_id || '#'}] ${user.full_name} (${user.email})`,
                                    user: user
                                };
                            })
                    }
                }
            },
            debug: true,
            delay: 200,
        }

        return (
            <tr className={ this.state.destroy ? 'd-none' : 'cacaca'}>
                <td className="w-50">
                    {renderHiddenId(item)}

                    <Select2
                        ref="user"
                        className="selector form-control"
                        name={this.field_name(item.id, "user_id")}
                        defaultValue={this.state.user_id}
                        value={this.state.user_id}
                        data = {dataUser()}
                        options={ _options }
                        onSelect={this.handleChange}
                        key={item.id}
                    />
                </td>
                <td>
                    <input
                        type="number"
                        defaultValue={this.state.quantity}
                        className="form-control"
                        min="0"
                        step="1"
                        name={this.field_name(item.id, "quantity")}
                        id={`item_quantity-${item.id}`}
                    />
                </td>
                <td className="text-right">
                    <button
                        onClick={this.handleDestroy}
                        className="btn btn-outline-danger"
                    >
                        <i className="fas fa-trash"></i>
                    </button>
                </td>
            </tr>
        )
    }
}

export default PsUserItem

PsUserItem.propTypes = {
    item: PropTypes.object.isRequired,
    clearErrors: PropTypes.func.isRequired,
}
