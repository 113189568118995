import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'

class Product extends React.Component {
    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this)
        this.inputRef = React.createRef()
        this.completedRef = React.createRef()
    }
    handleClick() {
        const { product } = this.props
        this.props.selectProduct(product)
    }
    label_amount(){
        if (this.props.is_credit)
            return "Credit: ";
        else
            return "Price: $";
    }
    render() {
        const { product } = this.props
        return (
            <div className="col-lg-4 mb-4 product-pointer" onClick={this.handleClick}>
                <div className="card bg-light text-black shadow border-left-success">
                    <div className="card-body">
                        <strong>{product.product_name}</strong>
                        { !this.props.is_follow_up &&
                            <div className="text-black-50 small">
                                {this.label_amount()} {product.unit_price}
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Product

Product.propTypes = {
    product: PropTypes.object.isRequired,
}
