$(document).ready(function() {
  $('#file').change(function() { 
    deleteTable();
    if ($('#file').val()) {
      document.getElementById('preview').classList.remove("disabled");
      document.getElementById('clear').classList.remove("disabled");
    } else {
      document.getElementById('preview').classList.add("disabled");
      document.getElementById('clear').classList.add("disabled");
    }
  });
  $('#preview').click(function() {
    deleteTable();
    var csv = $('#file');
    var csvFile = csv[0].files[0];
    var ext = csv.val().split(".").pop().toLowerCase();
    if (csvFile != undefined) {
      reader = new FileReader();
      reader.onload = function(e) {
        creditsData = e.target.result.split(/\r|\n|\r\n/).filter( function(elem) { return elem != "" } );
        var table = '<table id="preview-csv" class="table table-bordered">';
        for(var count = 0; count < creditsData.length; count++) {
          var cell_data = creditsData[count].split("," || ";");
          table += '<tr>';
          for(var cell_count = 0; cell_count < cell_data.length; cell_count++) {
            if(count == 0) table += `<th>${cell_data[cell_count]}</th>`;
            else table += `<td>${cell_data[cell_count]}</td>`;
          }
          table += '</tr>';
        }
        table += '</table>';
        $('#csv').html(table);
      }
      reader.readAsText(csvFile);
    }
  });
  $('#clear').click(function(){
    deleteTable();
  });

  function deleteTable() {
    var tablePreview = document.getElementById("preview-csv");
    if (tablePreview != null) {
      tablePreview.remove();
    }
  }
});
