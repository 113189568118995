import React from 'react';
import { IconWithInfo } from '../IconWithInfo';
import webImg from '../../../../assets/images/icons/iyoclub/web.png';
import phoneImg from '../../../../assets/images/icons/iyoclub/phone.png';
import emailImg from '../../../../assets/images/icons/iyoclub/email.png';

const IyoClubInfo = ({ data }) => {
  const {
    fullname = '',
    email_address,
    cell_phone,
    site_url,
    rank,
    customer_id,
  } = data || {};

  return (
    <>
      <div className='business-card-info'>
        <div className='column'>
          <span className='business-card-username-bold'>{fullname}</span>
          <span className='business-card-rank'>{rank.toUpperCase()}</span>
        </div>
        {cell_phone && (
          <IconWithInfo label={cell_phone} icon={phoneImg}/>
        )}
        {email_address && (
          <IconWithInfo
            label={email_address.toLowerCase()}
            icon={emailImg}
          />
        )}
        {site_url && (
          <IconWithInfo label={site_url.toLowerCase()} icon={webImg} />
        )}
      </div>
    </>
  );
};

export default IyoClubInfo;
