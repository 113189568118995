import React, { useCallback } from 'react';
import {
  TheoremInfo,
  MeasureAndMadeInfo,
  BravenlyGlobalInfo,
  IyoClubInfo,
  TopangaScentsInfo,
  AkannaInfo,
  ColorStreetInfo,
  JumpToHealthInfo,
  WeightlessInfo,
  AquabiovidaInfo,
} from './organizations';

const BusinessCard = ({ logo = false, img, data, organizationKey }) => {

  const size = {
    width: '270pt',
    minHeight: '162pt',
  };

  const renderOrganization = useCallback(() => {
    switch (organizationKey) {
      case 'measuremade':
        return <MeasureAndMadeInfo
          data={data}
        />
      case 'theorem':
        return <TheoremInfo
          data={data}
        />
      case 'bravenlyglobal':
        return <BravenlyGlobalInfo
          data={data}
        />
      case 'iyoclub':
        return <IyoClubInfo
          data={data}
        />
      case 'topangascents':
        return <TopangaScentsInfo
          data={data}
        />
      case 'akanna':
        return <AkannaInfo
          data={data}
        />
      case 'colorstreet':
        return <ColorStreetInfo
          data={data}
        />
      case 'jumptohealth':
        return <JumpToHealthInfo
          data={data}
        />
      case 'weightless':
        return <WeightlessInfo
          data={data}
        />
      case 'aquabiovida':
        return <AquabiovidaInfo
          data={data}
        />
      default:
        return ""
    }
  }, [organizationKey])

  return (
    <div
      className={logo ? `business-card-logo ${organizationKey}-logo`
        : `business-card ${organizationKey}-bg`}
      style={{
        ...size,
        backgroundImage: `url(${img})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {!logo && (
        <>
          {renderOrganization()}
        </>
      )}
    </div>
  );

}

export default BusinessCard;
